import React from "react"
import Text from '../Text'
import "./styles.scss"

const AboutInfo = () => (
  <div className="font-weight-light m-auto about-info">
    <Text.Header className="statement" weight="lighter">
      From keystrokes to bow strokes, every artistic moment inspires us to make
      the world a better place.{" "}
      <span className="font-weight-bold text-green">
        We aim to create a safe community for Chicago-based musicians to
        cultivate their talents while driving positive change.
      </span>
    </Text.Header>
    <Text.Header className="statement mt-4" weight="lighter">
      Whether it’s to enjoy a live stream performance, participate in a virtual
      competition, or simply see what’s new on the calendar — we hope our
      passion and purpose strike a chord.
    </Text.Header>
  </div>
)

export default AboutInfo
