import React from "react"
import MemberSection from "./MemberSection"
import "./styles.scss"

const MemberList = ({ members }) => (
  <div>
    {members.map((member, index) => (
      <div key={member.id} className="member-section">
        <MemberSection
          member={member}
          imgLast={index % 2 === 0 ? false : true}
        />
      </div>
    ))}
  </div>
)

export default MemberList
