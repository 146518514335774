import React from "react"
import Text from '../Text'
const MemberInfo = ({ firstName, lastName, bio, bio_intro }) => (
  <div className="pt-4 pt-md-0">
    <Text.Header>{`${firstName} ${lastName}`}</Text.Header>
    <Text.Header size="subheader" weight="normal">
      <div dangerouslySetInnerHTML={{ __html: bio_intro }}></div>
    </Text.Header>
    <Text.Normal>
      <div dangerouslySetInnerHTML={{ __html: bio }}></div>
    </Text.Normal>
  </div>
)

export default MemberInfo
