import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import MemberList from "../components/MemberList"
import AboutInfo from "../components/AboutInfo"
import ContributionsList from "../components/ContributionsList"

const AboutPage = ({ data }) => {
  return (
    <Layout noBreadcrumb banner={data.banners}>
      <Seo title="About John M. Fife & Mio Nakamura"
        description="John is proud to have partnered with his longtime friend, Mio, to start a promising community for 
        Chicago-based musicians to cultivate their talents." />
      <AboutInfo />
      <MemberList members={data.allMembers.nodes} />
      {data.allContributions && data.allContributions.nodes.length > 0 &&
        <ContributionsList contributions={data.allContributions.nodes} />
      }
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    allMembers(sort: { fields: first_name, order: DESC }) {
      nodes {
        id
        first_name
        last_name
        bio
        bio_intro
        image
        image_alt_desc
        localImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allContributions {
      nodes {
        contributor
        id
      }
    }
    banners(page_url: {eq: "/about"}) {
        link_label
        link_url
        text
        image
      }
  }
`
