import React from "react"
import MemberImage from "./MemberImage"
import MemberInfo from "./MemberInfo"

const MemberSection = ({ member, imgLast }) => {
  return (
    <div className="row">
      <div
        className={`col-md-4 order-first order-md-${imgLast ? "last" : "first"}`}
      >
        {member.image &&
          <MemberImage image={member.image} alt={member.image_alt_desc} />
        }
      </div>
      <div className={`col-md order-last order-md-${imgLast ? "first" : "last"}`}>
        <MemberInfo
          firstName={member.first_name}
          lastName={member.last_name}
          bio_intro={member.bio_intro}
          bio={member.bio}
        />
      </div>
    </div>
  )
}

export default MemberSection
