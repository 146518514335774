import React from 'react'
import Text from '../Text'

export const ContributionsList = ({ contributions }) => {
  return (
    <div className="contributions-list mt-5">
      <Text.Header>Contributions</Text.Header>
      {
        contributions && contributions.map((contribution, ind) => {
          return (
            <div key={ind}>
              <Text.Normal>{contribution.contributor}</Text.Normal>
            </div>
          )
        })
      }
    </div>
  )
}

export default ContributionsList